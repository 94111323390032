import React from "react";
import { Link } from "gatsby";
import classnames from "classnames";
import PropTypes from "prop-types";

import Layout from "./layout";
import SEO from "./seo";

import "./auth.css";

function Auth({
  customNav,
  title,
  headingTitle,
  headingDesc,
  children,
  note,
  onSubmit,
  location,
  mobileNote,
}) {
  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit();
  };

  return (
    <Layout
      hideMobileNav
      screenHeight={headingTitle}
      customNav={customNav}
      footer={false}
    >
      <SEO
        keywords={[`vackground`, `pattern`, `background`, `design`]}
        title={title}
        path={location?.pathname}
      />

      <div
        className={classnames("dev-vg-auth flex flex-col", {
          "flex-1": headingTitle,
        })}
      >
        <div className="flex-1 flex justify-center items-center">
          <div
            className={classnames("dev-vg-auth-content", {
              "dev-vg-auth-content--has-heading-title": headingTitle,
            })}
          >
            <div className="flex flex-col items-center mb-8">
              <h3 className="text-2.5xl font-bold tracking-tighteer leading-10 text-ui-gray-plus-4">
                {headingTitle}
              </h3>
              <p className="text-lg font-normal leading-8 tracking-tightsy text-ui-gray-plus-4 text-center">
                {headingDesc}
              </p>
            </div>
            {headingTitle ? (
              <form onSubmit={handleSubmit} className="flex flex-col gap-y-8">
                {children}
              </form>
            ) : (
              children
            )}
            {note && <div className="mt-8 text-center">{note}</div>}
            {mobileNote && (
              <div className="dev-vg-auth-content--mobile-note md:hidden text-center mt-6">
                {mobileNote}
              </div>
            )}
          </div>
        </div>
        <div className="flex items-center justify-center py-8 gap-x-8">
          <Link to="/">Home</Link>
          <Link to="/privacy">Privacy</Link>
          <Link to="/contact">Contact</Link>
        </div>
      </div>
    </Layout>
  );
}

Auth.propTypes = {
  customNav: PropTypes.element,
  title: PropTypes.string,
  headingTitle: PropTypes.string,
  headingDesc: PropTypes.string,
  children: PropTypes.element.isRequired,
  note: PropTypes.string,
  onSubmit: PropTypes.func,
  location: PropTypes.object,
  mobileNote: PropTypes.element,
};

export default Auth;
