import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link, navigate } from "gatsby";
import axios from "axios";

import {
  Input,
  Button,
  AlertModal,
  Hero,
  PasswordValidation,
} from "../components/common";

import Auth from "../components/auth";

import { useAuth } from "../hooks/useAuth";
import { ALERT_INITIAL_STATE, apiURL } from "../helpers";

import "./create-account.css";

function ResetPasswordPage({ location }) {
  const [state, setState] = useState({
    name: "",
    email: "",
    token: "",
    firstName: "",
    password: "",
    loading: false,
    finalReset: false,
    alert: { ...ALERT_INITIAL_STATE },
    validation: {
      email: true,
      firstName: true,
      password: true,
    },
  });
  useEffect(() => {
    if (typeof window !== "undefined") {
      const search = location.search;
      const query = new URLSearchParams(search);
      const [name, email, token] = [
        query.get("name"),
        query.get("email"),
        query.get("token"),
      ];
      if (email && token) {
        verifyToken(email, token, name);
      }
    }
  }, []);
  const verifyToken = async (email, token, name) => {
    setState({ ...state, name, email, token, loading: true });

    const { data } = await axios.get(
      apiURL +
        `/registrations?reset=true&name=${name}&email=${email}&token=${token}`
    );
    let finalReset = false;

    if (data.success && data?.code === "reset_token_matched") {
      finalReset = true;
    }
    setState({ ...state, name, email, token, loading: false, finalReset });
  };
  const { isAuthenticated } = useAuth();
  const handlePasswordReset = async (e) => {
    if (e) {
      e.preventDefault();
    }

    if (!state?.finalReset) {
      const { data } = await axios.post(apiURL + "/registrations?reset=true", {
        email: state.email,
      });

      switch (data.code) {
        case "reset_request_accepted":
          return navigate("/confirm-to-reset-password", {
            state: {
              email: state.email,
            },
          });
        case "reset_email_not_found":
          return setState({
            ...state,
            alert: {
              success: false,
              error: true,
              title: "You Don’t Have An Account With Us",
              content: (
                <>
                  Sorry, we haven’t found any active account with this email.
                  Please <Link to="/signup">create an account</Link> first. If
                  you think there is an issue with your account or purchase,
                  feel free to <Link to="/contact">contact us</Link>.
                </>
              ),
            },
          });
        case "soemthing_went_wrong":
          return setState({
            ...state,
            alert: {
              success: false,
              error: true,
              title: "Something went wrong",
              content:
                "We don't know what happened, but something went wrong! Please try again in a moment",
            },
          });
      }
    } else {
      const { data } = await axios.post(apiURL + "/registrations?reset=true", {
        email: state.email,
        token: state.token,
        password: state.password,
      });

      switch (data.code) {
        case "password_changed":
          return navigate("/");
        case "invalid_request":
        default:
          return navigate("/reset-password");
      }
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/");
    }
  }, [isAuthenticated]);
  const onValidate = (name, status) => {
    setState({ ...state, validation: { ...state.validation, [name]: status } });
  };
  return (
    <>
      <AlertModal
        type={state.alert.error ? "error" : "success"}
        show={state.alert.error || state.alert.success}
        title={state.alert.title}
        content={state.alert.content}
        onClose={() =>
          setState({
            ...state,
            alert: ALERT_INITIAL_STATE,
          })
        }
      />
      <Auth
        title="Reset Password"
        customNav={
          <div className="body-large hidden md:block">
            Already have an account?{" "}
            <Link className="text-brand-primary" to="/login">
              Login!
            </Link>
          </div>
        }
        secondaryForm={state?.finalReset}
        headingTitle={!state?.finalReset && "Forgot Your Password?"}
        headingDesc={!state?.finalReset && `Don’t worry, it happens!`}
        mobileNote={
          <>
            Already have an account? <Link to="/login">Login!</Link>
          </>
        }
      >
        {state?.finalReset ? (
          <>
            <section className="mt-20 mb-16">
              <Hero
                subTitle="Final Step"
                title={
                  <>
                    Hi {state.name},
                    <br />
                    Set A New Password Here
                  </>
                }
                description="Thanks! Your email has been confirmed. Please type your first name and a password to complete your account."
              />
            </section>
            <section className="mb-40">
              <form className="w-96 m-auto flex flex-col gap-8">
                <Input
                  pattern="(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$"
                  type="password"
                  value={state.password}
                  onChange={(password) => setState({ ...state, password })}
                  name="password"
                  label="Type a strong password"
                  placeholder="Work$4Home"
                  onValidate={onValidate}
                  tooltip={
                    <PasswordValidation
                      name={state.name}
                      email={state.email}
                      password={state.password}
                    />
                  }
                />
                <Button
                  hasMinWidth={false}
                  text="Set New Password & Login"
                  onClick={handlePasswordReset}
                  disabled={!state.password || !state.validation.password}
                />
              </form>
            </section>
          </>
        ) : (
          <>
            <Input
              pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"
              value={state.email}
              onChange={(email) => setState({ ...state, email })}
              name="email"
              label="Enter your account email here"
              placeholder="email@example.com"
              onValidate={onValidate}
            />
            {/* <Input
          value={state.password}
          onChange={(password) => setState({ ...state, password })}
          type="password"
          name="password"
          label="Password"
          placeholder="Enter your password"
        /> */}
            <Button
              hasMinWidth={false}
              text="Send Password Reset Email"
              disabled={!state.email || !state.validation.email}
              onClick={handlePasswordReset}
              forceTextInOneLine
            />
          </>
        )}
      </Auth>
    </>
  );
}

ResetPasswordPage.propTypes = {
  location: PropTypes.object,
};

export default ResetPasswordPage;
